import { useAppSelector } from "../app/hooks";

const useIntegrations = (currentViewAgencyUUID?: string) => {
    // We need both userInfo for multi-agency and userObject for single agency
    const { userInfo } = useAppSelector(({ user }) => user);
    const foundCurrentlyViewedAgency = userInfo?.all_available_agencies?.find((agency: any) => agency.agency_info.agency_uuid === currentViewAgencyUUID);

    // This tells us if the viewed agency has axon evidence enabled
    const agencyFeatureAxonevidence = foundCurrentlyViewedAgency?.agency_info?.feature_axonevidence;
    let axonEvidenceEnabled = false;

    if (currentViewAgencyUUID && foundCurrentlyViewedAgency) {
        // If current viewed agency matches the home agency in userInfo, then we can use the agencyFeatureAxonevidence.
        // That will give us the axon evidence feature flag, true or false.
        if (currentViewAgencyUUID === userInfo?.home_agency_membership?.agency_info?.agency_uuid) {
            axonEvidenceEnabled = agencyFeatureAxonevidence || false;
        } else {
            // This tells us if the user has access to axon evidence sharing, finding the share_axon_evidence_list is true or false.
            const agencyShareConfigAxonEvidence = foundCurrentlyViewedAgency?.applicable_sharing_config?.share_axon_evidence_list;
            // Check if the agency has axon evidence enabled and if agency has axon evidence sharing enabled.
            axonEvidenceEnabled = (agencyFeatureAxonevidence && agencyShareConfigAxonEvidence) || false;
        }
    }

    const personPhotosEnabled = userInfo?.home_agency_membership?.agency_info?.feature_personphotos || false;

    return {
        axonEvidenceEnabled,
        personPhotosEnabled
    };
};

export default useIntegrations;
