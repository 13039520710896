import { Card, Col, Container, Row } from "react-bootstrap";
import { faPerson, faPersonDress } from "@fortawesome/pro-light-svg-icons";
import { PERSON_ACCENT } from "../../utils/colors";
import { formatBirthdayAndDOB, formatName } from "../../utils/person";
import PersonTags from "../../components/tags/PersonTags";
import ResultHeader from "./ResultHeader";
import AgencyDescription from "../../components/AgencyDescription";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import ErrorBoundary from "../support/errorboundary/ErrorBoundary";
import PersonResultPhotoSection from "./PersonResultPhotoSection";
import useIntegrations from "../../hooks/useIntegrations";
import LinkWrapper from "../../components/LinkWrapper";

type OwnProps = {
    person: any;
    linkParams: any;
    mapHighlight?: boolean;
    wideView?: boolean;
    hidePhoto?: boolean;
};

const PersonResult = ({ person, linkParams, mapHighlight = false, wideView = false, hidePhoto = false }: OwnProps) => {
    const { personPhotosEnabled } = useIntegrations();
    const { constructPersonUrl } = useMultiAgency();

    const constructedPersonURL = constructPersonUrl(person?.agency_id, person, linkParams);

    return (
        <Card id={person.id} className="border-0">
            <ResultHeader
                mapHighlight={mapHighlight}
                icon={person.gender === "F" || person.gender?.toLowerCase() === "female" ? faPersonDress : faPerson}
                iconColor={PERSON_ACCENT}
                customIconClass="mx-1"
                leftContent={
                    <>
                        <div>
                            <LinkWrapper
                                isAnchor={constructedPersonURL.isNextFELink}
                                testId="person-result"
                                className="pendo_person_result d-inline-block fw-bold"
                                to={constructedPersonURL.url}
                            >
                                {formatName(person)}
                            </LinkWrapper>
                            {!!person.birthdate && <span className="ms-3">DOB: {formatBirthdayAndDOB(person.birthdate)}</span>}
                        </div>
                        <AgencyDescription className="text-secondary" agencyDescription={person?.agency_desc} />
                    </>
                }
            />
            <Card.Body className="overflow-hidden pt-3 fm-left-indent">
                <PersonTags tags={person?.alerts} agencyId={person?.agency_id} />
                <div className="d-flex gap-5">
                    {personPhotosEnabled && !hidePhoto && (
                        <ErrorBoundary>
                            <PersonResultPhotoSection person={person} />
                        </ErrorBoundary>
                    )}
                    <Container className="fw-normal text-nowrap px-0">
                        <Row>
                            <Col className="text-wrap" xs={12}>
                                <span>{person.full_address}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                className="text-wrap"
                                xs={12}
                                md={wideView ? 6 : !personPhotosEnabled ? 4 : 5}
                                lg={wideView ? 6 : !personPhotosEnabled ? 4 : 5}
                            >
                                <span>
                                    <span className="text-secondary me-2">Person ID:</span>
                                    <span>{person.person_id || person.id || "Unknown"}</span>
                                </span>
                            </Col>
                            <Col
                                className="text-wrap"
                                xs={12}
                                md={wideView ? 6 : !personPhotosEnabled ? 4 : 5}
                                lg={wideView ? 6 : !personPhotosEnabled ? 4 : 5}
                            >
                                <span>
                                    <span className="text-secondary me-2">DL#:</span>
                                    <span>{person.dl_complete || "Unknown"}</span>
                                </span>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={wideView ? 6 : !personPhotosEnabled ? 4 : 5} lg={wideView ? 6 : !personPhotosEnabled ? 4 : 5}>
                                <span>
                                    <span className="text-secondary me-2">Gender:</span>
                                    <span>{person.gender || "Unknown"}</span>
                                </span>
                            </Col>
                            <Col
                                className="text-wrap"
                                xs={12}
                                md={wideView ? 6 : !personPhotosEnabled ? 4 : 5}
                                lg={wideView ? 6 : !personPhotosEnabled ? 4 : 5}
                            >
                                <span>
                                    <span className="text-secondary me-2">Race:</span>
                                    <span>{person.race || "Unknown"}</span>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={wideView ? 6 : !personPhotosEnabled ? 4 : 5} lg={wideView ? 6 : !personPhotosEnabled ? 4 : 5}>
                                <span>
                                    <span className="text-secondary me-2">Eyes:</span>
                                    <span>{person.eyes || "Unknown"}</span>
                                </span>
                            </Col>
                            <Col xs={12} md={wideView ? 6 : !personPhotosEnabled ? 4 : 5} lg={wideView ? 6 : !personPhotosEnabled ? 4 : 5}>
                                <span>
                                    <span className="text-secondary me-2">Hair:</span>
                                    <span>{person.hair || "Unknown"}</span>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={wideView ? 6 : !personPhotosEnabled ? 4 : 5} lg={wideView ? 6 : !personPhotosEnabled ? 4 : 5}>
                                <span>
                                    <span className="text-secondary me-2">Height:</span>
                                    <span>{person.height || "Unknown"}</span>
                                </span>
                            </Col>
                            <Col xs={12} md={wideView ? 6 : !personPhotosEnabled ? 4 : 5} lg={wideView ? 6 : !personPhotosEnabled ? 4 : 5}>
                                <span>
                                    <span className="text-secondary me-2">Weight:</span>
                                    <span>{person.weight || "Unknown"}</span>
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Card.Body>
        </Card>
    );
};

export default PersonResult;
